import type { LocationQueryValue } from 'vue-router';
import type {
  ExternalCompany,
  LicensePlateData,
  NewPaidParkingPackage,
  TowCompany,
  TowSticker,
  TowTruck,
  VehicleModel,
} from '~/db/schema';
import type {
  CoordinatesType,
  NewUnitAndResidents,
  TowPriceWithContractIds,
  ResidentLoginResponse,
  StorageLotWithContractIds,
  TowDriver,
} from '~/types';

import type {
  MenuContract,
  ResetPassword,
  ResidentGuestContractReturn,
  TowPreview,
  UserWithFiles,
  UserWithFilesAndContracts,
} from '~/types';
import type {
  InferedChangesTruckSchema,
  InferedValidTruckSchema,
  InferedValidBlockedVehicleSchema,
  InferedAddManagerSchema,
  InferedValidHoldSchema,
  InferedValidCreateDevice,
  InferedValidExternalUserSchema,
} from '~/utils/sharedZod';

export const useApi = () => {
  const uselogIn = async (email: string, password: string) => {
    return await $fetch<UserWithFilesAndContracts>('/api/auth/login', {
      method: 'POST',
      body: { email, password },
    });
  };

  const useForgotPassword = async (
    email: string
  ): Promise<{ statusCode: number; statusMessage: string }> => {
    return await $fetch(`/api/auth/forgot-password`, {
      method: 'POST',
      body: { email },
    });
  };

  const useResetPassword = async (
    payload: ResetPassword
  ): Promise<{ statusCode: number; statusMessage: string }> => {
    return await $fetch('/api/auth/reset-password', {
      method: 'POST',
      body: payload,
    });
  };

  const useSignUp = async (
    payload: Object
  ): Promise<{ statusCode: number; statusMessage: string }> => {
    return await $fetch('/api/auth/sign-up', {
      method: 'POST',
      body: payload,
    });
  };

  const usePatchContract = async (
    id: string | LocationQueryValue | LocationQueryValue[],
    changes: Object
  ) => {
    return await $fetch(`/api/contracts/${id}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useAddApartmentUnit = async (
    apartmentUnit: NewUnitAndResidents & { notify: boolean }
  ) => {
    return await $fetch(`/api/units/`, {
      method: 'POST',
      body: apartmentUnit,
    });
  };

  const usePatchApartmentUnit = async (
    id: string | LocationQueryValue | LocationQueryValue[],
    changes: Object
  ) => {
    const result = await $fetch(`/api/units/${id}`, {
      method: 'PATCH',
      body: changes,
    });
    return result;
  };

  const useDeleteApartmentUnit = async (
    id: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/units/${id}`, {
      method: 'DELETE',
    });
  };

  const useAddVehicle = async (vehicle: Object) => {
    return await $fetch(`/api/vehicles`, {
      method: 'POST',
      body: vehicle,
    });
  };

  const usePatchVehicle = async (
    id: string | LocationQueryValue | LocationQueryValue[],
    changes: Object
  ) => {
    return await $fetch(`/api/vehicles/${id}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useDeleteVehicle = async (
    id: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/vehicles/${id}`, {
      method: 'DELETE',
    });
  };

  const useAddGuestVehicle = async (vehicle: Object) => {
    return await $fetch(`/api/vehicles/`, {
      method: 'POST',
      body: vehicle,
    });
  };

  const usePatchGuestVehicle = async (
    id: string | LocationQueryValue | LocationQueryValue[],
    changes: Object
  ) => {
    return await $fetch(`/api/vehicles/${id}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useDeleteGuestVehicle = async (
    id: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/vehicles/${id}`, {
      method: 'DELETE',
    });
  };

  const useAddBlockedVehicle = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    blockedVehicle: Object
  ) => {
    return await $fetch(`/api/contracts/${contractId}/blocked-vehicles`, {
      method: 'POST',
      body: blockedVehicle,
    });
  };

  const useDeleteBlockedVehicle = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    blockedVehicleId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/contracts/${contractId}/blocked-vehicles/${blockedVehicleId}`,
      {
        method: 'DELETE',
      }
    );
  };

  const useAddVipVehicle = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    vipVehicle: Object
  ) => {
    return await $fetch(`/api/contracts/${contractId}/vip-vehicles`, {
      method: 'POST',
      body: vipVehicle,
    });
  };

  const useDeleteVipVehicle = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    vipVehicleId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/contracts/${contractId}/vip-vehicles/${vipVehicleId}`,
      {
        method: 'DELETE',
      }
    );
  };

  const useSetContractWideResidentLimits = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    residentVehiclesLimit: Object
  ) => {
    return await $fetch(`/api/contracts/${contractId}/set-resident-limits`, {
      method: 'POST',
      body: residentVehiclesLimit,
    });
  };

  const useSetContractWideGuestLimits = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    guestLimit: Object
  ) => {
    return await $fetch(`/api/contracts/${contractId}/set-guest-limits`, {
      method: 'POST',
      body: guestLimit,
    });
  };

  const usePatchStickerVehicle = async (
    id: string | LocationQueryValue | LocationQueryValue[],
    changes: Object
  ) => {
    return await $fetch(`/api/sticker-lists/item/${id}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useUpdatePassword = async (changes: Object) => {
    return await $fetch(`/api/users/update-password`, {
      method: 'POST',
      body: changes,
    });
  };

  const useGetContracts = async (): Promise<MenuContract[]> => {
    return await $fetch(`/api/users/contracts`, {
      method: 'GET',
    });
  };

  const useGetCurrentUserDetails = async (): Promise<UserWithFiles> => {
    return await $fetch(`/api/users/me`, {
      method: 'GET',
    });
  };

  const useDeleteComplexManager = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    managerId: string
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/users/managers`, {
      method: 'DELETE',
      body: { contract_id: contractId, user_id: managerId },
    });
  };

  const useAddManager = async (
    contractId: string | LocationQueryValue | LocationQueryValue[],
    { name, email, phone }: { name: string; email: string; phone: string }
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/users/managers`, {
      method: 'POST',
      body: { contract_id: contractId, email, name, phone },
    });
  };

  const useNotifyResidentsWithoutVehicle = async (
    contractId: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/contracts/${contractId}/notify-residents`, {
      method: 'POST',
    });
  };

  const useNotifyResidentsWithoutVehicleBySMS = async (
    contractId: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ statusCode: number; statusMessage: string }> => {
    // @ts-ignore
    return await $fetch(`/api/contracts/${contractId}/notify-residents-sms`, {
      method: 'POST',
    });
  };

  const useNotifyApartmentUnit = async (unitId: string) => {
    return await $fetch(`/api/units/${unitId}/notify`, {
      method: 'POST',
    });
  };

  const usePatchFile = async (
    pathToResourceContainingFile: string,
    fileName: string,
    file: File
  ) => {
    const formData = new FormData();
    formData.append(fileName, file);
    return await $fetch(`/api/${pathToResourceContainingFile}/files`, {
      method: 'PATCH',
      body: formData,
    });
  };

  const useLicensePlateLookup = async (
    licensePlate: string,
    state: string
  ): Promise<LicensePlateData> => {
    return await $fetch(`/api/tows/preview-tow`, {
      method: 'POST',
      body: {
        license_plate: licensePlate,
        state: state,
      },
    });
  };
  const useImageLicensePlateLookup = async (uploadEvent: any) => {
    const formData = new FormData();
    formData.append('image', uploadEvent[0]);

    return await $fetch(`/api/tows/preview-tow-image`, {
      method: 'POST',
      body: formData,
    });
  };

  const useCreateNewTow = async (towData: TowPreview) => {
    return await $fetch(`/api/tows/`, {
      method: 'POST',
      body: towData,
    });
  };

  const useAddTowImages = async (
    towId: string,
    public_images?: Blob[] | null,
    private_images?: Blob[] | null
  ) => {
    const formData = new FormData();
    if (!public_images && !private_images) {
      return;
    }
    if (public_images) {
      public_images.forEach((image) => {
        formData.append('tow_image_public', image);
      });
    }

    if (private_images) {
      private_images.forEach((image) => {
        formData.append('tow_image_private', image);
      });
    }

    return await $fetch(`/api/tows/${towId}/images`, {
      method: 'POST',
      body: formData,
    });
  };

  const useAddContract = async (contract: InferedValidContractSchema) => {
    return await $fetch(`/api/contracts`, {
      method: 'POST',
      body: contract,
    });
  };

  const useGetManagersRelatedToTowCompany = async (
    id: string,
    searchText: string
  ) => {
    return await $fetch(
      `/api/tow-companies/${id}/managers/search?search=${encodeURIComponent(
        searchText
      )}`,
      {
        method: 'GET',
      }
    );
  };

  const useGetActiveContracts = async (
    searchText: string,
    guest: boolean = false
  ): Promise<ResidentGuestContractReturn[]> => {
    return await $fetch(
      `/api/contracts/search?search=${encodeURIComponent(searchText)}`,
      {
        method: 'GET',
      }
    );
  };

  const useGetActiveGuestParkingContracts = async (
    searchText: string
  ): Promise<ResidentGuestContractReturn[]> => {
    return await $fetch(
      `/api/contracts/search?search=${encodeURIComponent(
        searchText
      )}&guest=true`,
      {
        method: 'GET',
      }
    );
  };

  const useAddTowUser = async (
    towCompanyId: string,
    towUser: InferedValidTowUserSchema
  ): Promise<InferedValidTowUserSchema> => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/tow-users`, {
      method: 'POST',
      body: towUser,
    });
  };

  const usePatchTowUser = async (
    towCompanyId: string,
    towUserId: string,
    towUser: InferedValidTowUserSchema
  ): Promise<InferedValidTowUserSchema> => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/tow-users/${towUserId}`,
      {
        method: 'PATCH',
        body: towUser,
      }
    );
  };

  const useDeleteTowUser = async (
    towCompanyId: string,
    towUserId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      ` /api/tow-companies/${towCompanyId}/tow-users/${towUserId}`,
      {
        method: 'DELETE',
      }
    );
  };

  const useEnableDisableTowUser = async (
    towCompanyId: string,
    towUserId: string
  ): Promise<InferedValidTowUserSchema> => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/tow-users/${towUserId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useAddManagerToComplex = async (
    payload: InferedAddManagerToContractSchema
  ): Promise<any> => {
    return await $fetch(`/api/users/managers/add-manager-to-contract`, {
      method: 'POST',
      body: payload,
    });
  };

  const useAddStickerManual = async (
    payload: InferedValidLicensePlateStickerSchema
  ) => {
    return await $fetch(`/api/sticker-lists/item`, {
      method: 'POST',
      body: payload,
    });
  };

  const useAddSticker = async (
    imageObjects: any,
    contract: { id: string },
    stickerList: { id: string },
    coords?: CoordinatesType,
    private_comments?: string,
    public_comments?: string,
    reasons?: string[]
  ): Promise<TowSticker> => {
    const formData = new FormData();
    for (const imageObject of imageObjects) {
      const name = imageObject.selected
        ? 'sticker_image_main'
        : 'sticker_image';
      formData.append(name, imageObject.file);
    }

    if (coords) {
      formData.append('coordinates', JSON.stringify(coords));
    }

    formData.append('contract', JSON.stringify(contract));
    formData.append('sticker_list', JSON.stringify(stickerList));

    if (private_comments) {
      formData.append('private_comments', JSON.stringify({ private_comments }));
    }

    if (public_comments) {
      formData.append('public_comments', JSON.stringify({ public_comments }));
    }

    if (reasons) {
      for (const reason of reasons) {
        formData.append('reason', JSON.stringify(reason));
      }
    }
    return await $fetch(`/api/sticker-lists/item/create-by-image`, {
      method: 'POST',
      body: formData,
    });
  };

  const useEnableDisableContract = async (
    contractId: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/contracts/${contractId}/enable-disable`, {
      method: 'POST',
    });
  };

  const useAddStickerList = async (contractId: string, tow_date: string) => {
    return await $fetch(`/api/contracts/${contractId}/sticker-lists`, {
      method: 'POST',
      body: {
        tow_date,
      },
    });
  };

  const useSendPermitEmail = async (email: string) => {
    return true;
  };

  const useConvertStickerVehicleToTow = async (
    itemId: string,
    payload: any
  ) => {
    return await $fetch(`/api/sticker-lists/item/${itemId}/convert-to-tow`, {
      method: 'POST',
      body: payload,
    });
  };

  const useDeleteStickerListItemImage = async (
    itemId: string,
    imageId: string
  ) => {
    return await $fetch(`/api/sticker-lists/item/${itemId}/${imageId}`, {
      method: 'DELETE',
    });
  };

  const useUploadStickerImages = async (
    itemId: string,
    images: Blob[]
  ): Promise<any> => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('sticker_image', image);
    });
    return await $fetch(`/api/sticker-lists/item/${itemId}/files`, {
      method: 'POST',
      body: formData,
    });
  };

  const useGuestRegisterGuest = async (
    vehicle: InferedValidGeneratePermitSchema
  ) => {
    return await $fetch(`/api/guests/register`, {
      method: 'POST',
      body: vehicle,
    });
  };

  const useVerifyGuestLoginForUnit = async (unitForm: any) => {
    return await $fetch(`/api/guests/verify-unit`, {
      method: 'POST',
      body: { ...unitForm },
    });
  };

  const useAddResidentGuestVehicle = async (
    vehicle: InferedValidCreateResidentGuestVehicle
  ) => {
    return await $fetch('/api/residents/register-guest', {
      method: 'POST',
      body: vehicle,
    });
  };

  const useUpdateResidentPincode = async (
    residentId: string,
    pincode: string
  ) => {
    return await $fetch(`/api/residents/${residentId}`, {
      method: 'PATCH',
      body: {
        resident_pin_code: pincode,
      },
    });
  };

  const useUpdateGuestPincode = async (residentId: string, pincode: string) => {
    return await $fetch(`/api/residents/${residentId}`, {
      method: 'PATCH',
      body: {
        guest_pin_code: pincode,
      },
    });
  };

  const useGetGuestVehiclesForResident = async (apartmentUnitId: string) => {
    return await $fetch(`/api/units/${apartmentUnitId}/guests`, {
      method: 'GET',
      body: {
        apartment_unit_id: apartmentUnitId,
      },
    });
  };

  const useGetTrucks = async (towCompanyId: string): Promise<TowTruck[]> => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/trucks`, {
      method: 'GET',
    });
  };

  const useGetTowPrices = async (
    towCompanyId: string
  ): Promise<TowPriceWithContractIds[]> => {
    let result = await $fetch<any[]>(
      `/api/tow-companies/${towCompanyId}/tow-prices`,
      {
        method: 'GET',
      }
    );

    // hack on tow prices prices to make them string as editable dropdown is erroring out on number values
    if (result && result.length > 0) {
      result = result.map((towPrice) => {
        towPrice.price = Number.parseFloat(towPrice.price).toFixed(2);
        return towPrice;
      });
    }

    return result;
  };

  const useGetStorageLots = async (
    towCompanyId: string
  ): Promise<StorageLotWithContractIds[]> => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/storage-lots`, {
      method: 'GET',
    });
  };

  const useResidentLogin = async (
    payload: InferedResidentLoginSchema
  ): Promise<ResidentLoginResponse> => {
    return await $fetch('/api/residents/login', {
      method: 'POST',
      body: payload,
    });
  };

  const useResidentLogout = async () => {
    return await $fetch('/api/residents/logout', {
      method: 'GET',
    });
  };

  const getGuestVehicles = async (unitId: string) => {
    return await $fetch(`/api/units/${unitId}/guests`, {
      method: 'GET',
    });
  };

  const usePatchResident = async (
    residentId: string,
    changes: InferedUpdateResidentSchema
  ) => {
    return await $fetch(`/api/residents/${residentId}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useAddTowPrice = async (
    towCompanyId: string,
    towPrice: InferedValidTowPriceSchema
  ) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/tow-prices`, {
      method: 'POST',
      body: towPrice,
    });
  };

  const usePatchTowPrice = async (
    towCompanyId: string,
    towPriceId: string,
    changes: InferedChangesTowPriceSchema
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/tow-prices/${towPriceId}`,
      {
        method: 'PATCH',
        body: changes,
      }
    );
  };

  const useEnableDisableTowPrice = async (
    towCompanyId: string,
    towPriceId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/tow-prices/${towPriceId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useAddStorageLot = async (
    towCompanyId: string,
    storageLot: InferedValidStorageLotSchema
  ) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/storage-lots`, {
      method: 'POST',
      body: storageLot,
    });
  };

  const usePatchStorageLot = async (
    towCompanyId: string,
    storageLotId: string,
    changes: InferedChangesStorageLotSchema
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/storage-lots/${storageLotId}`,
      {
        method: 'PATCH',
        body: changes,
      }
    );
  };

  const useEnableDisableStorageLot = async (
    towCompanyId: string,
    storageLotId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/storage-lots/${storageLotId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useAddTruck = async (
    towCompanyId: string,
    truck: InferedValidTruckSchema
  ) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/trucks`, {
      method: 'POST',
      body: truck,
    });
  };

  const usePatchTruck = async (
    towCompanyId: string,
    truckId: string,
    changes: InferedChangesTruckSchema
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/trucks/${truckId}`,
      {
        method: 'PATCH',
        body: changes,
      }
    );
  };

  const useEnableDisableTruck = async (
    towCompanyId: string,
    truckId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/trucks/${truckId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useGetContractsByTowPriceId = async (
    towPriceId: string
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/contracts/tow-prices/${towPriceId}`, {
      method: 'GET',
    });
  };

  const useGetTowPriceAndContractsByContractId = async (contractId: string) => {
    return await $fetch(`/api/contracts/${contractId}/tow-prices`, {
      method: 'GET',
    });
  };

  const useGetContractsWithTowPrices = async (towCompanyId: string) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/contracts`, {
      method: 'GET',
    });
  };

  const useSetTowPrice = async (
    towCompanyId: string,
    towPriceId: string,
    contractIds: string[]
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/tow-prices/${towPriceId}/set-default-tow-price`,
      {
        method: 'POST',
        body: {
          contract_ids: contractIds,
        },
      }
    );
  };

  const useGetContractsByStorageId = async (
    storageId: string
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/contracts/storage-lots/${storageId}`, {
      method: 'GET',
    });
  };

  const useGetStorageAndContractsByContractId = async (contractId: string) => {
    return await $fetch(`/api/contracts/${contractId}/storage-lots`, {
      method: 'GET',
    });
  };

  const useGetContractsWithStorages = async (towCompanyId: string) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/contracts`, {
      method: 'GET',
    });
  };

  const useSetStorages = async (
    towCompanyId: string,
    storageId: string,
    contractIds: string[]
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/storage-lots/${storageId}/set-default-storage`,
      {
        method: 'POST',
        body: {
          contract_ids: contractIds,
        },
      }
    );
  };

  const useGetDrivers = async (towCompanyId: string): Promise<TowDriver[]> => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/drivers`, {
      method: 'GET',
    });
  };

  const useGetTowUsers = async (towCompanyId: string): Promise<TowDriver[]> => {
    return await $fetch(`/api/tow-companies/${towCompanyId}/tow-users`, {
      method: 'GET',
    });
  };

  const useUpdateTow = async (
    towId: string,
    changes: any
  ): Promise<TowSticker> => {
    return await $fetch(`/api/tows/${towId}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useDeleteTow = async (towId: string): Promise<{ id: string }> => {
    return await $fetch(`/api/tows/${towId}`, {
      method: 'DELETE',
    });
  };

  const useCancelTow = async (towId: string): Promise<{ id: string }> => {
    return await $fetch(`/api/tows/${towId}/cancel`, {
      method: 'POST',
    });
  };

  const useDeleteTowImage = async (towId: string, imageId: string) => {
    return await $fetch(`/api/tows/${towId}/images/${imageId}`, {
      method: 'DELETE',
    });
  };

  const useUploadTowImages = async (towId: string, images: Blob[]) => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('tow_image_public', image);
    });
    return await $fetch(`/api/tows/${towId}/images`, {
      method: 'POST',
      body: formData,
    });
  };

  const useFindMyCarSearch = async (contractId: string, searchText: string) => {
    return await $fetch(`/api/vehicles/findmycar`, {
      method: 'POST',
      body: {
        contract_id: contractId,
        license_plate_or_last_4_vin: searchText,
      },
    });
  };

  const useImport = async (
    towCompanyId: string,
    contractId: string,
    payload: any
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/contracts/${contractId}/import`,
      {
        method: 'POST',
        body: payload,
        timeout: 600000,
      }
    );
  };

  const usePreviewImport = async (
    towCompanyId: string,
    contractId: string,
    payload: any
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/contracts/${contractId}/preview-import`,
      {
        method: 'POST',
        body: payload,
      }
    );
  };

  const useGetUnitVehicles = async (unitId: string) => {
    return await $fetch(`/api/units/${unitId}/vehicles`, {
      method: 'GET',
    });
  };

  const useUpdateTowCompany = async (towCompanyId: string, changes: any) => {
    return await $fetch(`/api/tow-companies/${towCompanyId}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useValidateGuestPaidGuestPermit = async (id: string) => {
    return await $fetch(`/api/guests/validate-paid-permit`, {
      method: 'POST',
      body: { id },
    });
  };

  const useSendContractTowNotification = async (
    towId: string,
    email: string
  ) => {
    return await $fetch(`/api/tows/${towId}/email-contract`, {
      method: 'POST',
      body: { email },
    });
  };

  const useSendCustomerInvoice = async (towId: string, email: string) => {
    return await $fetch(`/api/tows/${towId}/email-customer`, {
      method: 'POST',
      body: { email },
    });
  };

  const useAddTowCompany = async (towCompany: InferedNewTowCompanySchema) => {
    return await $fetch(`/api/admin/tow-companies`, {
      method: 'POST',
      body: towCompany,
    });
  };

  const useEnableDisableTowCompany = async (
    towCompanyId: string
  ): Promise<TowCompany> => {
    return await $fetch(
      `/api/admin/tow-companies/${towCompanyId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useAddVehicleModel = async (
    vehicleModel: InferedValidVehicleModelSchema
  ) => {
    return await $fetch(`/api/vehicle-models`, {
      method: 'POST',
      body: vehicleModel,
    });
  };

  const useDeleteVehicleModel = async (
    vehicleModelId: string
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/vehicle-models/${vehicleModelId}`, {
      method: 'DELETE',
    });
  };

  const useAddSystemBlockedVehicle = async (
    systemBlockedVehicle: InferedValidBlockedVehicleSchema
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/admin/system-blocked-vehicles`, {
      method: 'POST',
      body: systemBlockedVehicle,
    });
  };

  const useDeleteSystemBlockedVehicle = async (
    systemBlockedVehicleId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/system-blocked-vehicles/${systemBlockedVehicleId}`,
      {
        method: 'DELETE',
      }
    );
  };

  const useDeletePaidParkingPackage = async (
    contractId: string,
    paidParkingPackageId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/contracts/${contractId}/paid-parking-packages/${paidParkingPackageId}`,
      {
        method: 'DELETE',
      }
    );
  };

  const useAddPaidParkingPackage = async (
    contractId: string,
    paidParkingPackage: NewPaidParkingPackage
  ): Promise<NewPaidParkingPackage> => {
    return await $fetch(
      `/api/admin/contracts/${contractId}/paid-parking-packages`,
      {
        method: 'POST',
        body: paidParkingPackage,
      }
    );
  };

  const useEnableDisableContractPaidParking = async (
    contractId: string
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/contracts/${contractId}/enable-disable-paid-parking`,
      {
        method: 'POST',
      }
    );
  };

  const useSendEmailNoCharge = async (towId: string) => {
    return await $fetch(`/api/tows/${towId}/email-no-charge`, {
      method: 'POST',
    });
  };

  const useSendStorageLotInvoice = async (towId: string, payload = null) => {
    return await $fetch(`/api/tows/${towId}/email-storage-lot-invoice`, {
      body: {
        email: payload,
      },
      method: 'POST',
    });
  };

  const useGetContractManager = async (contractManagerId: string) => {
    return await $fetch(`/api/users/managers/${contractManagerId}`, {
      method: 'GET',
    });
  };

  const useUpdateContractManager = async (
    contractManagerId: string,
    changes: any
  ) => {
    return await $fetch(`/api/users/managers/${contractManagerId}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const useSetNewContractManager = async (
    contractId: string,
    payload: InferedAddManagerToContractSchema
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/contracts/${contractId}/set-new-contract-manager`,
      {
        method: 'PATCH',
        body: payload,
      }
    );
  };

  const useSetNewTowCompanyAdmin = async (
    towCompanyId: string,
    payload: InferedAddManagerSchema
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/tow-companies/${towCompanyId}/set-new-tow-admin`,
      {
        method: 'PATCH',
        body: payload,
      }
    );
  };

  const useContractHold = async (
    contractId: string,
    changes: InferedValidHoldSchema
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/contracts/${contractId}/hold`, {
      method: 'POST',
      body: changes,
    });
  };

  const useResetResidentPincodes = async (residentId: string) => {
    return await $fetch(`/api/residents/${residentId}/reset-pincodes`, {
      method: 'POST',
    });
  };

  const useIsVehicleExisting = async (
    contractId: string,
    licensePlate: string
  ) => {
    return await $fetch(`/api/tows/is-vehicle-existing`, {
      method: 'POST',
      body: {
        contract_id: contractId,
        license_plate: licensePlate,
      },
    });
  };

  const useEnableDisableContractManager = async (
    // contractId: string | LocationQueryValue | LocationQueryValue[],
    userId: string
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/users/managers/${userId}/enable-disable`, {
      method: 'POST',
    });
  };

  const useDeleteDevice = async (deviceId: string): Promise<{ id: string }> => {
    return await $fetch(`/api/admin/devices/${deviceId}`, {
      method: 'DELETE',
    });
  };

  const useAddDevice = async (
    device: InferedValidCreateDevice
  ): Promise<{ id: string }> => {
    return await $fetch(`/api/admin/devices`, {
      method: 'POST',
      body: device,
    });
  };

  const useEnableDisableUnitResidentVehicleRegistration = async (
    unitId: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/units/${unitId}/enable-disable-resident-vehicles`,
      {
        method: 'POST',
      }
    );
  };

  const useEnableDisableUnitGuestVehicleRegistration = async (
    unitId: string | LocationQueryValue | LocationQueryValue[]
  ): Promise<{ id: string }> => {
    return await $fetch(
      `/api/admin/units/${unitId}/enable-disable-guest-vehicles`,
      {
        method: 'POST',
      }
    );
  };

  const useSearchExternalCompanies = async (searchText: string) => {
    return await $fetch(
      `/api/external-companies/search?search=${encodeURIComponent(searchText)}`,
      {
        method: 'GET',
      }
    );
  };

  const useSetExternalCompany = async (
    contractId: string,
    externalCompanyId: string
  ) => {
    return await $fetch(`/api/contracts/${contractId}/set-external-company`, {
      method: 'POST',
      body: { external_company_id: externalCompanyId },
    });
  };

  const useAddExternalCompany = async (
    externalCompany: InferedNewExternalCompanySchema
  ) => {
    return await $fetch(`/api/admin/external-companies`, {
      method: 'POST',
      body: externalCompany,
    });
  };

  const useUpdateExternalCompany = async (
    externalCompanyId: string,
    changes: any
  ) => {
    return await $fetch(`/api/admin/external-companies/${externalCompanyId}`, {
      method: 'PATCH',
      body: changes,
    });
  };

  const usePatchExternalUser = async (
    externalCompanyId: string,
    externalUserId: string,
    externalUser: InferedUpdateExternalUserSchema
  ): Promise<InferedValidExternalUserSchema> => {
    return await $fetch(
      `/api/external-companies/${externalCompanyId}/external-users/${externalUserId}`,
      {
        method: 'PATCH',
        body: externalUser,
      }
    );
  };

  const useEnableDisableExternalCompany = async (
    externalCompanyId: string
  ): Promise<ExternalCompany> => {
    return await $fetch(
      `/api/admin/external-companies/${externalCompanyId}/enable-disable`,
      {
        method: 'POST',
      }
    );
  };

  const useEnableDisableExternalUser = async (
    externalCompanyId: string,
    externalUserId: string
  ): Promise<InferedValidExternalUserSchema> => {
    return await $fetch(
      `/api/amin/external-companies/${externalCompanyId}/external-users/${externalUserId}`,
      {
        method: 'POST',
      }
    );
  };

  const useGetManagersBySearch = async (searchText: string) => {
    return await $fetch(
      `/api/admin/managers/search?search=${encodeURIComponent(searchText)}`,
      {
        method: 'GET',
      }
    );
  };

  const useResendManagerInvitation = async (
    contractId: string,
    userId: string
  ) => {
    return await $fetch(
      `/api/contracts/${contractId}/managers/${userId}/resend-welcome-email`,
      {
        method: 'POST',
      }
    );
  };

  const updateContractManagerPassword = async (
    towCompanyId: string,
    userId: string,
    payload: InferedSetPasswordSchema
  ) => {
    return await $fetch(
      `/api/tow-companies/${towCompanyId}/managers/${userId}/update-password`,
      {
        method: 'POST',
        body: payload,
      }
    );
  };

  const useGetVehicleModels = async (): Promise<VehicleModel[]> => {
    return await $fetch(`/api/vehicle-models/all`, {
      method: 'GET',
    });
  };

  return {
    useNotifyResidentsWithoutVehicleBySMS,
    useNotifyApartmentUnit,
    useValidateGuestPaidGuestPermit,
    useVerifyGuestLoginForUnit,
    useResidentLogout,
    useFindMyCarSearch,
    useGetGuestVehiclesForResident,
    useGuestRegisterGuest,
    useUploadStickerImages,
    useGetActiveGuestParkingContracts,
    useDeleteStickerListItemImage,
    useConvertStickerVehicleToTow,
    useGetActiveContracts,
    useGetManagersRelatedToTowCompany,
    useSearchExternalCompanies,
    useAddTowImages,
    useCreateNewTow,
    uselogIn,
    useForgotPassword,
    usePatchContract,
    useAddApartmentUnit,
    usePatchApartmentUnit,
    useDeleteApartmentUnit,
    useAddVehicle,
    usePatchVehicle,
    useDeleteVehicle,
    useAddGuestVehicle,
    usePatchGuestVehicle,
    useDeleteGuestVehicle,
    useAddBlockedVehicle,
    useDeleteBlockedVehicle,
    useAddVipVehicle,
    useDeleteVipVehicle,
    useSetContractWideResidentLimits,
    useSetContractWideGuestLimits,
    usePatchStickerVehicle,
    useUpdatePassword,
    useGetContracts,
    useGetCurrentUserDetails,
    useResetPassword,
    useSignUp,
    useDeleteComplexManager,
    useAddManager,
    useNotifyResidentsWithoutVehicle,
    usePatchFile,
    useLicensePlateLookup,
    useImageLicensePlateLookup,
    useAddContract,
    useAddTowUser,
    usePatchTowUser,
    useDeleteTowUser,
    useEnableDisableTowUser,
    useAddManagerToComplex,
    useAddSticker,
    useEnableDisableContract,
    useAddStickerList,
    useSendPermitEmail,
    useAddResidentGuestVehicle,
    useUpdateResidentPincode,
    useUpdateGuestPincode,
    useGetTrucks,
    useGetTowPrices,
    useGetStorageLots,
    useResidentLogin,
    getGuestVehicles,
    usePatchResident,
    useAddTowPrice,
    usePatchTowPrice,
    useEnableDisableTowPrice,
    useSetTowPrice,
    useSetStorages,
    useAddStorageLot,
    usePatchStorageLot,
    useEnableDisableStorageLot,
    useAddTruck,
    usePatchTruck,
    useEnableDisableTruck,
    useGetContractsByTowPriceId,
    useGetTowPriceAndContractsByContractId,
    useGetContractsWithTowPrices,
    useGetContractsByStorageId,
    useGetStorageAndContractsByContractId,
    useGetContractsWithStorages,
    useUpdateTow,
    useGetDrivers,
    useDeleteTow,
    useCancelTow,
    useDeleteTowImage,
    useUploadTowImages,
    useImport,
    usePreviewImport,
    useGetUnitVehicles,
    useUpdateTowCompany,
    useSendContractTowNotification,
    useSendStorageLotInvoice,
    useSendCustomerInvoice,
    useAddStickerManual,
    useAddTowCompany,
    useEnableDisableTowCompany,
    useDeleteVehicleModel,
    useAddVehicleModel,
    useAddSystemBlockedVehicle,
    useDeleteSystemBlockedVehicle,
    useDeletePaidParkingPackage,
    useAddPaidParkingPackage,
    useEnableDisableContractPaidParking,
    useSendEmailNoCharge,
    useGetContractManager,
    useUpdateContractManager,
    useSetNewContractManager,
    useSetNewTowCompanyAdmin,
    useContractHold,
    useResetResidentPincodes,
    useIsVehicleExisting,
    useEnableDisableContractManager,
    useDeleteDevice,
    useAddDevice,
    useEnableDisableUnitResidentVehicleRegistration,
    useEnableDisableUnitGuestVehicleRegistration,
    useSetExternalCompany,
    useAddExternalCompany,
    useUpdateExternalCompany,
    usePatchExternalUser,
    useEnableDisableExternalCompany,
    useEnableDisableExternalUser,
    useGetManagersBySearch,
    useGetTowUsers,
    useResendManagerInvitation,
    updateContractManagerPassword,
    useGetVehicleModels,
  };
};
