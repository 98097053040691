import type { Colors, ColorsPalette } from '~/types';

export const reasons = [
  {
    label: 'Inoperable',
    reason: 'inoperable',
  },
  {
    label: 'Abandoned',
    reason: 'abandoned',
  },
  {
    label: 'Flat Tire',
    reason: 'flat_tire',
  },
  {
    label: 'Expired Registration',
    reason: 'expired_registration',
  },
  {
    label: 'Expired Inspection',
    reason: 'expired_inspection',
  },
  {
    label: 'Missing Parts',
    reason: 'missing_parts',
  },
  {
    label: 'Wrecked',
    reason: 'wrecked',
  },
  {
    label: 'Leaking Fluids',
    reason: 'leaking_fluids',
  },
  {
    label: 'Other',
    reason: 'other',
  },
];

export enum UserStatuses {
  active = 'success',
  has_not_joined_yet = 'contrast',
  disabled = 'danger',
}

export type UserStatusesKey = keyof typeof UserStatuses;

export enum Reasons {
  inoperable = '#B481F7',
  abandoned = '#4097FF',
  flat_tire = '#FF8484',
  expired_registration = '#FD9800',
  expired_inspection = '#f43f5e',
  missing_parts = '#FFC75F',
  wrecked = '#2A9D8F',
  leaking_fluids = '#E76F51',
  other = '#F4A261',
}

export enum Roles {
  super_admin = '#000000',
  tow_admin = '#ff0000',
  tow_staff = '#00ff00',
  tow_driver = '#0000ff',
  contract_manager = '#FD9800',
  contract_staff = '#00ffff',
  resident = '#ff00ff',
  disabled = '#fc88d5',
}

export const TowCompanyRoles = [
  {
    label: 'Tow Admin',
    value: 'tow_admin',
  },
  {
    label: 'Tow Staff',
    value: 'tow_staff',
  },
  {
    label: 'Tow Driver',
    value: 'tow_driver',
  },
];
// var(--primary-500) green in primflex
// border-color: var(--green-500);
// border: 1px solid #53C53780;

const lightColors: Colors = {
  primary: '#3B82F6',
  secondary: '#64748B',
  success: '#53C537',
  info: '#0ea5e9',
  warning: '#f97316',
  help: '#A855F7',
  danger: '#FB4F57',
  contrast: '#515c66',
};

const dimColors: Colors = {
  primary: '#60a5fa',
  secondary: '#94a3b8',
  success: '#53C537',
  info: '#38bdf8',
  warning: '#fb923c',
  help: '#c084fc',
  danger: '#FB4F57',
  contrast: '#cacfdb',
};

export const allColors: ColorsPalette = {
  dim: dimColors,
  light: lightColors,
};

export const defaultInvoiceDisclaimer = `You may direct all complaints to Texas Department of Licensing & Regulation at P.O. Box 12157, Austin TX 78711 or call
800-803-9202 or through the website http://www.tdlr.texas.gov or email to intake@tdlr.texas.gov.
OCCUPATION CODE 2308-RIGHTS OF OWNER OR OPERATOR OF A STORED OR BOOTED VEHICLE
The owner or operator of a vehicle which has been removed and placed in a vehicle storage facility without the consent of the owner is
entitled to a hearing on whether probable cause existed for the removal of the vehicle pursuant to Sec. 23308.452 of the Texas
Occupations Code. Notice requirements: The person entitled to this hearing must deliver before the 14th day after the date the towing
company or vehicle storage facility provided to the vehicle owner or operator the information necessary for the vehicle owner or
operator to complete the material for the request for hearing, excluding Saturdays, Sundays, and legal holidays. The hearing request
must be submitted to the Justice of the Peace from which the motor vehicle was towed. The request must contain: (1) name, address, &
telephone number of the owner or operator of the vehicle; (2) the location from which the vehicle was removed or in which the vehicle
was booted; (3) the date when the vehicle was removed or booted; (4) the name, address, and telephone number of the person or law
enforcement agency that authorized the removal or booting; (5) the name, address, and telephone number of the vehicle storage facility
in which the vehicle was placed; (6) the name, address, and telephone number of the towing company that removed the vehicle or of the
booting company that installed a boot on the vehicle; (7) a copy of any receipt or notification that the owner or operator received from
the towing company, the booting company, or the vehicle storage facility; and (8) the vehicle was removed from or booted in a parking
facility:(A) one or more photographs that show the location and text of any sign posted at the facility restricting parking of vehicles; or
(B) a statement that no sign restricting parking was posted at the parking facility. The Court may charge a filing fee up to $20 for a
hearing and may award court cost to the prevailing party, reasonable cost of the photographs to a vehicle owner or operator who is the
prevailing party, and possible overcharges of towing fees. The hearing request may be filed in any Justice Court in (i) the county from
which the vehicle was towed; or (ii) for booted vehicles, the county in which the parking facility is located. The Internet website
maintained by the Office of Court Administration of the Texas Judicial System that contains the name, address, and telephone number
of each justice court in that county is (http://card.txcourts.gov/DirectorySearch.aspx).`;

export const defaultDropReceiptDisclaimer = `I acknowledge that I have inspected my vehicle and that there has been no damage caused by Private Property Tow, LLC, I am personally responsible for entering my email address as {{email}} and I was informed that I could pay via Cash OR ALL MAJOR CREDIT CARDS and my payment method was my own choice. I paid a total of {{price}} and there was no additional charges whatsoever outside of the amount stated here.`;

export const dateMatchModes = [
  { value: 'dateIs', label: 'Date is' },
  { value: 'dateIsNot', label: 'Date is not' },
  { value: 'dateBefore', label: 'Date before' },
  { value: 'dateAfter', label: 'Date after' },
  { value: 'lte', label: 'Less than or equal' },
  { value: 'gte', label: 'Greater than or equal' },
];

export const TowStatuses = [
  {
    label: 'Requested',
    value: 'requested',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Completed Dropped On Site',
    value: 'completed_dropped_on_site',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

export const UserStatusesOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
];
