export const useClearAuthData = () => {
  const authStore = useAuthStore();
  const managerStore = useManagerStore();
  const towUserStore = useTowUserStore();
  const residentStore = useResidentStore();

  return {
    clearStoresAndCookies: () => {
      const managerCookie = useCookie('managerStore');
      managerCookie.value = null;
      const towUserCookie = useCookie('towUserStore');
      towUserCookie.value = null;
      authStore.isAuthenticated = false;
      managerStore.clearStore();
      towUserStore.clearStore();
      residentStore.clearStore();
    },
  };
};
