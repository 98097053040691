import { useAuthStore } from '~/stores/authStore';
import { useExternalUserStore } from '~/stores/externalUserStore';
import { useTowUserStore } from '~/stores/towUserStore';
import type { UserWithFilesAndContracts } from '~/types';

export default defineNuxtPlugin(async (NuxtApp) => {
  const authStore = useAuthStore();
  const managerStore = useManagerStore();
  const externalStore = useExternalUserStore();
  const towUserStore = useTowUserStore();
  const { uselogIn } = useApi();
  const { clearStoresAndCookies } = useClearAuthData();

  const handleLogin = async (
    username: string,
    password: string
  ): Promise<UserWithFilesAndContracts> => {
    const result = await uselogIn(username, password);
    authStore.isAuthenticated = true;
    authStore.name = result.name;
    authStore.email = result.email;
    authStore.role = result.role;
    if (result.files) {
      authStore.avatarUrl = result.files.find(
        (file) => file.file_type === 'avatar'
      )?.file_url;
    }

    authStore.userId = result.id;
    authStore.roleId = result.roleId;
    if (
      result.role === 'contract_manager' ||
      result.role === 'contract_staff'
    ) {
      if (result.contracts.length) {
        managerStore.contracts = result.contracts.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }

      if (managerStore.contracts.length) {
        managerStore.selectedContract = managerStore.contracts[0];
      }
      authStore.timezone = managerStore.contracts[0].timezone;
    }
    if (
      result.role === 'tow_admin' ||
      result.role === 'tow_driver' ||
      result.role === 'tow_staff'
    ) {
      towUserStore.towCompany = result.tow_company;
      towUserStore.towContracts = result.contracts;
      authStore.timezone = towUserStore?.towCompany?.timezone;
    }

    if (result.role === 'super_admin') {
      authStore.timezone = 'America/Chicago';
    }

    if (result.role === 'external_user') {
      if (result.contracts.length) {
        externalStore.contracts = result.contracts.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }

      if (externalStore.contracts.length) {
        externalStore.selectedContract = externalStore.contracts[0];
      }
      authStore.timezone = externalStore.contracts[0].timezone;
    }

    return result;
  };
  let logOut = async () => {
    try {
      await $fetch('/api/auth/logout');
    } catch (error) {}
    clearStoresAndCookies();
  };
  return {
    provide: {
      auth: { handleLogin, logOut },
    },
  };
});
