const unsecuredPaths = [
  '/login',
  '/reset-password?',
  '/forgot-password',
  'sign-up?',
  '/guest',
  '/resident',
  '/resident/dashboard',
  '/guest',
  '/findmycar',
];

const residentPaths = ['/resident/dashboard'];

export const checkIfPathUnsecured = (path: string): any => {
  {
    return unsecuredPaths.some((unsecuredPath) => {
      if (unsecuredPath.match(/\?$/g)) {
        return path.includes(unsecuredPath);
      } else {
        return path === unsecuredPath;
      }
    });
  }
};

export const checkIfResidentPath = (path: string): any => {
  {
    return residentPaths.some((residentPath) => {
      if (residentPath.match(/\?$/g)) {
        return path.includes(residentPath);
      } else {
        return path === residentPath;
      }
    });
  }
};
