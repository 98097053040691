import type { ToastObject } from '~/types';

export const useAppToast = () => {
  const toast = useToast();

  return {
    toastSuccess: ({ summary, detail = null, life = 3000 }: ToastObject) => {
      toast.add({
        summary,
        detail,
        severity: 'success',
        life: life <= -1 ? undefined : life,
      });
    },
    toastError: ({ summary, detail = null, life = 3000 }: ToastObject) => {
      toast.add({
        summary,
        detail,
        severity: 'error',
        life: life <= -1 ? undefined : life,
      });
    },
    toastInfo: ({ summary, detail = null, life = 3000 }: ToastObject) => {
      toast.add({
        summary,
        detail,
        severity: 'info',
        life: life <= -1 ? undefined : life,
      });
    },
  };
};
