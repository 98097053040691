import { defineStore } from 'pinia';

import type { ComputedRole } from '~/types';

const { useGetCurrentUserDetails } = useApi();

export const useAuthStore = defineStore(
  'authStore',
  () => {
    const isAuthenticated = ref(false);
    const name = ref();
    const email = ref();
    const role = ref();
    const avatarUrl = ref();
    const userId = ref();
    const roleId = ref();
    const timezone = ref();

    const getCurrentUser = () => {
      return {
        isAuthenticated: isAuthenticated.value,
        name: name.value,
        email: email.value,
        role: role.value,
        avatarUrl: avatarUrl.value,
        userId: userId.value,
        id: roleId.value,
      };
    };

    const getCurrentUserDetails = async () => {
      const result = await useGetCurrentUserDetails();
      avatarUrl.value = result!.files.find(
        (file) => file.file_type === 'avatar'
      )?.file_url;
    };

    const computedRole = computed<ComputedRole>(() => {
      switch (role.value) {
        case 'super_admin':
          return 'super_admin';
        case 'tow_admin':
          return 'tow';
        case 'tow_staff':
          return 'tow';
        case 'tow_driver':
          return 'tow';
        case 'contract_manager':
          return 'manager';
        case 'contract_staff':
          return 'manager';
        case 'resident':
          return 'resident';
        case 'external_user':
          return 'external';
        default:
          return 'disabled';
      }
    });

    // option nr: 3
    watch(isAuthenticated, async (value) => {
      if (!value) {
        if (role.value.includes('resident')) {
          navigateTo('/resident');
        } else {
          navigateTo('/login');
        }
      }
    });

    return {
      isAuthenticated,
      name,
      email,
      role,
      avatarUrl,
      userId,
      roleId,
      getCurrentUser,
      getCurrentUserDetails,
      computedRole,
      timezone,
    };
  },
  { persist: { storage: window.localStorage } }
);
