import { ofetch } from 'ofetch';

export default defineNuxtPlugin(async (_nuxtApp) => {
  const { csrf } = useCsrf();
  const { toastInfo } = useAppToast();

  // @ts-ignore
  globalThis.$fetch = ofetch.create({
    onRequest({ options }) {
      if (csrf) {
        options.headers = { 'csrf-token': csrf };
      } else {
        // console.log('Not authenticated');
      }
      options.retry = 0;
    },
    onRequestError({ error }) {
      console.log('Request error', error);
    },
    onResponseError({ request, response, options }) {
      if (response._data.statusMessage === 'CSRF Token Mismatch') {
        toastInfo({
          summary:
            'The site is going to be reloaded due to a new update. We apologize for the inconvenience.',
          detail: 'Please wait a few seconds...',
          life: -1,
        });

        setTimeout(() => {
          reloadNuxtApp();
        }, 4000);
      } else if (
        response._data.statusCode === 401 &&
        response._data.statusMessage === 'Unauthorized'
      ) {
        showError({ message: 'Unauthorized', statusCode: 401 });
      } else if (response._data.statusCode === 500) {
        // toastError({
        //   summary: 'Error',
        //   detail: response._data.statusMessage,
        // });
      }
    },
  });
});
