
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = {};

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt });
  vueApp.use(ConfirmationService);
vueApp.use(DialogService);
vueApp.use(ToastService);
  vueApp.directive('badge', BadgeDirective);
vueApp.directive('tooltip', Tooltip);
vueApp.directive('ripple', Ripple);
});
        