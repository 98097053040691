<!-- Main app button use it if button should contain text, it accepts both primevue icon (icon) & iconify icon (iconName) -->
<template>
  <Button
    :label="props.label"
    v-wave
    :outlined="props.outlined"
    :severity="props.color"
    :class="[buttonClass]"
    :disabled="props.disabled"
    :pt:label:class="props.fontWeight"
    :style="[{ 'border-color': borderColor }]"
    v-tooltip.left="props.tooltip"
  >
    <template #icon>
      <Icon
        mode="svg"
        v-if="iconName"
        :name="iconName"
        :size="props.iconSize"
        :class="{ 'mr-1': props.label }"
      />
    </template>
  </Button>
</template>

<script setup lang="ts">
import type { ColorsKey } from '~/types';

const appConfigStore = useAppConfigStore();

const props = defineProps({
  label: String,
  tooltip: String,
  iconName: {
    type: String,
  },
  color: {
    type: String as PropType<ColorsKey>,
    default: 'primary',
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: String,
    default: '24',
  },
  buttonClass: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean>
    >,
    default: 'min-w-min px-3',
  },
  fontWeight: {
    type: String,
    default: 'font-bold',
  },
});

const borderColor = computed(() => {
  if ((props.color as ColorsKey) in allColors[appConfigStore.colorMode]) {
    let color = allColors[appConfigStore.colorMode][props.color as ColorsKey];

    if (appConfigStore.colorMode === 'dim') {
      color += '40';
    }

    return color;
  }

  return props.color;
});
</script>
