<template>
  <AppToast />
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <div id="fullscreen-animation-wrapper">
    <div id="color-mode-animation-element"></div>
  </div>
</template>

<script setup lang="ts">
import { useMagicKeys } from '@vueuse/core';
import { useClipboard } from '@vueuse/core';
import { faker } from '@faker-js/faker';

const appConfigStore = useAppConfigStore();
const keys = useMagicKeys();

const shiftCtrlP = keys['Shift+AltLeft+p'];
const shiftCtrlE = keys['Shift+AltLeft+e'];
const shiftCtrlN = keys['Shift+AltLeft+n'];
const shiftCtrlC = keys['Shift+AltLeft+c'];
const shiftCtrlA = keys['Shift+AltLeft+a'];

const source = ref('');
const { copy } = useClipboard({ source });

watch(shiftCtrlP, (v) => {
  if (v) {
    source.value = faker.helpers.fromRegExp(/[0-9]{3}-[0-9]{3}-[0-9]{4}/);
    copy(source.value);
  }
  console.log('Shift + Ctrl + p have been pressed');
});

watch(shiftCtrlE, (v) => {
  if (v) {
    source.value = faker.internet.email();
    copy(source.value);
  }
  console.log('Shift + Ctrl + e have been pressed');
});

watch(shiftCtrlN, (v) => {
  if (v) {
    source.value = faker.person.fullName();
    copy(source.value);
  }
  console.log('Shift + Ctrl + n have been pressed');
});

watch(shiftCtrlC, (v) => {
  if (v) {
    source.value = faker.company.name();
    copy(source.value);
  }
  console.log('Shift + Ctrl + c have been pressed');
});

watch(shiftCtrlA, (v) => {
  if (v) {
    source.value = faker.location.streetAddress();
    copy(source.value);
  }
  console.log('Shift + Ctrl + a have been pressed');
});

const computedTheme = computed<string>(() => {
  return appConfigStore.colorMode === 'dim'
    ? '/css/theme/denim/theme-dim.css'
    : '/css/theme/denim/theme-light.css';
});

useHead({
  title: 'Apartment Permits',
  bodyAttrs: {
    class: 'main-body',
  },
  link: [
    {
      rel: 'stylesheet',
      href: computedTheme,
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon-180x180.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-32x32.png',
    },
  ],
});
</script>
