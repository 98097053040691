import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_adVF2uRka6 from "/src/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_AASx6Gh4zc from "/src/node_modules/v-wave/nuxt/runtime/plugin.js";
import plugin_UC48QI1yOB from "/src/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/src/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/src/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/src/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_2TTjvwQPRx from "/src/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import auth_vT9JWWT9pN from "/src/plugins/auth.ts";
import ofetch_TmnSnS1Swe from "/src/plugins/ofetch.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_adVF2uRka6,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_AASx6Gh4zc,
  plugin_UC48QI1yOB,
  plugin_WLsn00x1qh,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_2TTjvwQPRx,
  plugin_AUP22rrBAc,
  auth_vT9JWWT9pN,
  ofetch_TmnSnS1Swe
]