import { defineStore } from 'pinia';

import type { ColorsPaletteKey } from '~/types';

export const useAppConfigStore = defineStore(
  'appConfigStore',
  () => {
    const sidebarVisible = ref(true);
    const colorMode = ref<ColorsPaletteKey>('light');
    const windowWidth = ref();

    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      windowWidth.value = window.innerWidth;
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    const isMobile = computed(() => {
      return windowWidth.value <= 576;
    });
    return {
      sidebarVisible,
      colorMode,
      isMobile,
      windowWidth,
    };
  },
  { persist: { storage: window.localStorage } }
);
