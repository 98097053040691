<template>
  <ClientOnly>
    <NuxtLayout name="auth">
      <div
        class="flex align-items-center justify-content-center min-h-screen min-w-screen"
        :class="[
          appConfigStore.colorMode === 'dim'
            ? 'text-gray-300'
            : 'text-gray-900',
        ]"
      >
        <div class="flex flex-column w-full align-items-center">
          <template v-if="error?.statusCode === 404">
            <h1>404!</h1>
            <p class="text-center">
              Sorry, that page doesn't exist. Please wait as we redirect you
              back
            </p>
            <p></p>
            <p>{{ counter }}</p>
          </template>
          <template v-else-if="error?.statusCode === 401">
            <h2 class="text-center">
              Your login session has expired, please log in again
            </h2>
            <p class="text-center">
              You are being redirected to login page, please wait...
            </p>
            <p>{{ counter }}</p>
          </template>
          <template v-else-if="isUnhandledError">
            <h1>Dang</h1>
            <p>
              <strong>{{ error?.message }}</strong>
            </p>
            <p>It looks like something broke.</p>
            <p>Sorry about that.</p>
            <p>
              <AppButton label="Go back home" @click="handleError" />
            </p>
          </template>
        </div>
      </div>
    </NuxtLayout>
  </ClientOnly>
</template>

<script setup lang="ts">
const appConfigStore = useAppConfigStore();
const { clearStoresAndCookies } = useClearAuthData();
const router = useRouter();
const error: Ref = useError();
const authStore = useAuthStore();
const isUnhandledError = ref(false);

//created a counter which will begin to count from 5 to 0 on the page mounted event
//the value of the counter will be displayed on the page
//the handleError method will be called when the value of the counter reaches 0

const counter = ref(2);

onMounted(async () => {
  if (error.value.statusCode !== 401 && error.value.statusCode !== 404) {
    isUnhandledError.value = true;
    return;
  }
  const interval = await setInterval(async () => {
    counter.value--;
    if (counter.value === 0) {
      if (authStore.isAuthenticated) {
        if (error.value.statusCode === 401) {
          clearInterval(interval);
          await handleError();
          return;
        }

        router.back();
      } else {
        clearInterval(interval);
        await handleError();
      }
    }
  }, 1000);
});

const handleError = async () => {
  if (window.location.href.includes('/resident/')) {
    await clearError({
      redirect: '/resident',
    });
    clearStoresAndCookies();
  } else {
    await clearError({
      redirect: '/login',
    });
    clearStoresAndCookies();
  }
};

const computedTheme = computed<string>(() => {
  return appConfigStore.colorMode === 'dim'
    ? '/css/theme/denim/theme-dim.css'
    : '/css/theme/denim/theme-light.css';
});

useHead({
  title: 'Apartment Permits',
  bodyAttrs: {
    class: 'main-body',
  },
  link: [
    {
      rel: 'stylesheet',
      href: computedTheme,
    },
  ],
});
</script>
