import { defineStore } from 'pinia';

import type { MenuContract, TowCompanyType } from '~/types';

export const useTowUserStore = defineStore(
  'towUserStore',
  () => {
    const { useGetContracts } = useApi();
    const towContracts = ref<MenuContract[]>([]);
    const towCompany = ref<TowCompanyType>();

    const getContracts = async () => {
      const result = await useGetContracts();
      towContracts.value = [...result];
    };

    const getDrivers = () => {
      if (!towCompany.value) return [];
      return towCompany.value.related_users.filter(
        (user) => user.role === 'tow_driver'
      );
    };

    const clearStore = () => {
      towContracts.value = [];
      towCompany.value = undefined;
    };

    watch(
      () => towCompany.value,
      () => {
        useAuthStore().timezone = towCompany.value?.timezone;
      },
      { deep: true }
    );

    const getContractById = (id: string) => {
      return towContracts.value.find((contract) => contract.id === id);
    };

    return {
      towContracts,
      towCompany,
      getContracts,
      getDrivers,
      clearStore,
      getContractById,
    };
  },
  { persist: { storage: window.localStorage } }
);
