import { default as profile4xkbZgafKZMeta } from "/src/pages/account/profile.vue?macro=true";
import { default as companyH0SgClH7k0Meta } from "/src/pages/company.vue?macro=true";
import { default as complexes_45managersq0MpppIPBjMeta } from "/src/pages/complexes-managers.vue?macro=true";
import { default as indexOYTw87crVZMeta } from "/src/pages/complexes/blocked-vehicles/index.vue?macro=true";
import { default as create_45towjCM3RMK6IkMeta } from "/src/pages/complexes/create-tow.vue?macro=true";
import { default as index6CycH3f9tYMeta } from "/src/pages/complexes/details/index.vue?macro=true";
import { default as indexEuVKFcqW2UMeta } from "/src/pages/complexes/history-logs/index.vue?macro=true";
import { default as indexwZw1DeBGRzMeta } from "/src/pages/complexes/settings/index.vue?macro=true";
import { default as indexDylW9aW3MDMeta } from "/src/pages/complexes/sticker-lists/index.vue?macro=true";
import { default as indexU8H4XggZOVMeta } from "/src/pages/complexes/tows/[tow-id]/details/index.vue?macro=true";
import { default as indexZmSx3CO7ToMeta } from "/src/pages/complexes/tows/index.vue?macro=true";
import { default as indexdd0Rxhzo3lMeta } from "/src/pages/complexes/units/index.vue?macro=true";
import { default as indexZlqoWlK0XGMeta } from "/src/pages/complexes/vehicles/index.vue?macro=true";
import { default as indexvuf2ne5lJFMeta } from "/src/pages/complexes/vip-vehicles/index.vue?macro=true";
import { default as create_45stickerNcQK8yNX0PMeta } from "/src/pages/create-sticker.vue?macro=true";
import { default as index6wQgId9jWPMeta } from "/src/pages/findmycar/index.vue?macro=true";
import { default as forgot_45passwordhkQDFGWAYaMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as indexUPiPsZLmERMeta } from "/src/pages/guest/index.vue?macro=true";
import { default as history_45logsiKrZxi89T2Meta } from "/src/pages/history-logs.vue?macro=true";
import { default as importBYchBti911Meta } from "/src/pages/import.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as insightsEl3ZkJE30cMeta } from "/src/pages/insights.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as reportsUAeaLCYqUYMeta } from "/src/pages/reports.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as indexUtuAtnlAgiMeta } from "/src/pages/resident/dashboard/index.vue?macro=true";
import { default as indexVZkEVLfNjPMeta } from "/src/pages/resident/index.vue?macro=true";
import { default as sign_45upjeNWAFVoRzMeta } from "/src/pages/sign-up.vue?macro=true";
import { default as sticker_45listsriwwIqokKHMeta } from "/src/pages/sticker-lists.vue?macro=true";
import { default as blocked_45vehiclesvQHvVG1dgCMeta } from "/src/pages/super-admin/blocked-vehicles.vue?macro=true";
import { default as devicesRD3g7mhfgPMeta } from "/src/pages/super-admin/devices.vue?macro=true";
import { default as external_45companiesYtWDKFak1hMeta } from "/src/pages/super-admin/external-companies.vue?macro=true";
import { default as external_45contractsIEfjw6wrDrMeta } from "/src/pages/super-admin/external-contracts.vue?macro=true";
import { default as paid_45parkingoFFDKww71NMeta } from "/src/pages/super-admin/paid-parking.vue?macro=true";
import { default as tow_45companiesqTnzboGocUMeta } from "/src/pages/super-admin/tow-companies.vue?macro=true";
import { default as transaction_45logsgBHttiVcI1Meta } from "/src/pages/super-admin/transaction-logs.vue?macro=true";
import { default as units_45blocked_45vehiclesvWKqNrXBIJMeta } from "/src/pages/super-admin/units-blocked-vehicles.vue?macro=true";
import { default as vehiclesszlJCZbROxMeta } from "/src/pages/super-admin/vehicles.vue?macro=true";
import { default as tow_45teamNoeDfFOaEsMeta } from "/src/pages/tow-team.vue?macro=true";
import { default as towsE95jgYhjq6Meta } from "/src/pages/tows.vue?macro=true";
import { default as vehiclesvs8JZlykDsMeta } from "/src/pages/vehicles.vue?macro=true";
export default [
  {
    name: "account-profile",
    path: "/account/profile",
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "complexes-managers",
    path: "/complexes-managers",
    component: () => import("/src/pages/complexes-managers.vue").then(m => m.default || m)
  },
  {
    name: "complexes-blocked-vehicles",
    path: "/complexes/blocked-vehicles",
    component: () => import("/src/pages/complexes/blocked-vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-create-tow",
    path: "/complexes/create-tow",
    component: () => import("/src/pages/complexes/create-tow.vue").then(m => m.default || m)
  },
  {
    name: "complexes-details",
    path: "/complexes/details",
    component: () => import("/src/pages/complexes/details/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-history-logs",
    path: "/complexes/history-logs",
    component: () => import("/src/pages/complexes/history-logs/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-settings",
    path: "/complexes/settings",
    component: () => import("/src/pages/complexes/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-sticker-lists",
    path: "/complexes/sticker-lists",
    component: () => import("/src/pages/complexes/sticker-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-tows-towid-details",
    path: "/complexes/tows/:towid()/details",
    component: () => import("/src/pages/complexes/tows/[tow-id]/details/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-tows",
    path: "/complexes/tows",
    component: () => import("/src/pages/complexes/tows/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-units",
    path: "/complexes/units",
    component: () => import("/src/pages/complexes/units/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-vehicles",
    path: "/complexes/vehicles",
    component: () => import("/src/pages/complexes/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "complexes-vip-vehicles",
    path: "/complexes/vip-vehicles",
    component: () => import("/src/pages/complexes/vip-vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "create-sticker",
    path: "/create-sticker",
    component: () => import("/src/pages/create-sticker.vue").then(m => m.default || m)
  },
  {
    name: "findmycar",
    path: "/findmycar",
    meta: index6wQgId9jWPMeta || {},
    component: () => import("/src/pages/findmycar/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordhkQDFGWAYaMeta || {},
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "guest",
    path: "/guest",
    meta: indexUPiPsZLmERMeta || {},
    component: () => import("/src/pages/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "history-logs",
    path: "/history-logs",
    component: () => import("/src/pages/history-logs.vue").then(m => m.default || m)
  },
  {
    name: "import",
    path: "/import",
    component: () => import("/src/pages/import.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexvsa0KwJWqiMeta || {},
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/src/pages/insights.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/src/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "resident-dashboard",
    path: "/resident/dashboard",
    meta: indexUtuAtnlAgiMeta || {},
    component: () => import("/src/pages/resident/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "resident",
    path: "/resident",
    meta: indexVZkEVLfNjPMeta || {},
    component: () => import("/src/pages/resident/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upjeNWAFVoRzMeta || {},
    component: () => import("/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sticker-lists",
    path: "/sticker-lists",
    component: () => import("/src/pages/sticker-lists.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-blocked-vehicles",
    path: "/super-admin/blocked-vehicles",
    component: () => import("/src/pages/super-admin/blocked-vehicles.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-devices",
    path: "/super-admin/devices",
    component: () => import("/src/pages/super-admin/devices.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-external-companies",
    path: "/super-admin/external-companies",
    component: () => import("/src/pages/super-admin/external-companies.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-external-contracts",
    path: "/super-admin/external-contracts",
    component: () => import("/src/pages/super-admin/external-contracts.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-paid-parking",
    path: "/super-admin/paid-parking",
    component: () => import("/src/pages/super-admin/paid-parking.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-tow-companies",
    path: "/super-admin/tow-companies",
    component: () => import("/src/pages/super-admin/tow-companies.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-transaction-logs",
    path: "/super-admin/transaction-logs",
    component: () => import("/src/pages/super-admin/transaction-logs.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-units-blocked-vehicles",
    path: "/super-admin/units-blocked-vehicles",
    component: () => import("/src/pages/super-admin/units-blocked-vehicles.vue").then(m => m.default || m)
  },
  {
    name: "super-admin-vehicles",
    path: "/super-admin/vehicles",
    component: () => import("/src/pages/super-admin/vehicles.vue").then(m => m.default || m)
  },
  {
    name: "tow-team",
    path: "/tow-team",
    component: () => import("/src/pages/tow-team.vue").then(m => m.default || m)
  },
  {
    name: "tows",
    path: "/tows",
    component: () => import("/src/pages/tows.vue").then(m => m.default || m)
  },
  {
    name: "vehicles",
    path: "/vehicles",
    component: () => import("/src/pages/vehicles.vue").then(m => m.default || m)
  }
]