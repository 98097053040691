import { defineStore } from 'pinia';

import type { MenuContract } from '~/types';

export const useExternalUserStore = defineStore(
  'externalUserStore',
  () => {
    const { useGetContracts } = useApi();
    const contracts = ref<MenuContract[]>([]);
    const selectedContract = ref<MenuContract | undefined>(undefined);

    const getContracts = async () => {
      const result = await useGetContracts();
      contracts.value = [...result];

      try {
        if (selectedContract.value) {
          const selectedContractId = selectedContract.value.id;
          selectedContract.value = contracts.value.find(
            (contract) => contract.id === selectedContractId
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const clearStore = () => {
      contracts.value = [];
      selectedContract.value = undefined;
    };

    watch(
      () => selectedContract.value,
      () => {
        useAuthStore().timezone = selectedContract.value?.timezone;
      }
    );

    return {
      contracts,
      selectedContract,
      getContracts,
      clearStore,
    };
  },
  { persist: { storage: window.localStorage } }
);
