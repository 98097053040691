<template>
  <Toast position="top-right">
    <template #container="{ message, closeCallback }" class="surface-overlay">
      <div class="p-toast-message-content p-2">
        <div
          class="justify-content-center align-items-center align-self-center"
        >
          <Icon
            mode="svg"
            :name="
              isSuccess(message.severity)
                ? 'i-heroicons:check-circle-16-solid'
                : 'i-heroicons:x-circle-16-solid'
            "
            size="64"
          />
        </div>
        <div class="flex flex-column w-full px-2">
          <span class="font-semibold text-sm">{{ message.summary }}</span>
          <span class="mt-2 text-sm text-indent">{{ message.detail }}</span>
        </div>
        <div>
          <Button
            :icon="isSuccess(message.severity) ? 'pi pi-check' : 'pi pi-times'"
            class="p-toast-icon-close toast-close-button"
            aria-label="Submit"
            size="small"
            text
            rounded
            :iconClass="computedCloseIconClass(message.severity)"
            @click="closeCallback"
          />
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
const isSuccess = (severity: string) => severity === 'success';

const computedCloseIconClass = (severity: string) => {
  switch (severity) {
    case 'success':
      return 'text-green-500';
    case 'info':
      return 'text-blue-500';
    case 'error':
      return 'text-red-500';
    default:
      return 'text-red-500';
  }
};
</script>
