import { useAuthStore } from '~/stores/authStore';

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  const managerStore = useManagerStore();

  if (to.fullPath === '/') {
    if (!authStore.isAuthenticated) {
      if (authStore.role === 'resident') {
        return clearError({
          redirect: '/resident',
        });
      } else {
        return clearError({
          redirect: '/login',
        });
      }
    } else {
      if (authStore.role === 'resident') {
        return clearError({
          redirect: '/resident/dashboard',
        });
      } else if (authStore.role.includes('tow')) {
        return clearError({
          redirect: '/vehicles',
        });
      } else if (authStore.role.includes('contract')) {
        return clearError({
          redirect: `/complexes/units?id=${managerStore.selectedContract?.id}`,
        });
      } else if (authStore.role.includes('external')) {
        return clearError({
          redirect: `/complexes/vehicles?id=${managerStore.selectedContract?.id}`,
        });
      }
    }
  }

  if (!checkIfPathUnsecured(to.fullPath) && !authStore.isAuthenticated) {
    showError({ statusMessage: 'Unauthorized', statusCode: 401, data: { to } });
    return abortNavigation();
  }

  // if resident paths and role is not resident, unauthorized
  if (checkIfResidentPath(to.fullPath) && authStore.role !== 'resident') {
    showError({ statusMessage: 'Unauthorized', statusCode: 401, data: { to } });
    return abortNavigation();
  }

  if (
    authStore.role === 'resident' &&
    !checkIfPathUnsecured(to.fullPath) &&
    !to.fullPath.includes('resident')
  ) {
    return abortNavigation();
  }

  if (authStore.role !== 'super_admin' && to.fullPath.includes('super-admin')) {
    return abortNavigation();
  }
});
